export const ERROR = 'ERROR'


// #Accounts
export const CONNECTION_CONNECTED = 'CONNECTION_CONNECTED'
export const CONNECTION_DISCONNECTED = 'CONNECTION_DISCONNECTED'

export const GET_BALANCES = 'GET_BALANCES'
export const BALANCES_RETURNED = 'BALANCES_RETURNED'

export const GET_BALANCES_LIGHT = 'GET_BALANCES_LIGHT'
export const BALANCES_LIGHT_RETURNED = 'BALANCES_LIGHT_RETURNED'

export const GET_VAULT_BALANCES = 'GET_VAULT_BALANCES'
export const VAULT_BALANCES_RETURNED = 'VAULT_BALANCES_RETURNED'


// #Dashboard
export const GET_DASHBOARD_SNAPSHOT = 'GET_DASHBOARD_SNAPSHOT'
export const DASHBOARD_SNAPSHOT_RETURNED = 'DASHBOARD_SNAPSHOT_RETURNED'

export const GET_USD_PRICE = 'GET_USD_PRICE'
export const USD_PRICE_RETURNED = 'USD_PRICE_RETURNED'


// #Earn
export const INVEST = 'INVEST'
export const INVEST_RETURNED = 'INVEST_RETURNED'

export const INVEST_ALL = 'INVEST_ALL'
export const INVEST_ALL_RETURNED = 'INVEST_ALL_RETURNED'

export const REDEEM = 'REDEEM'
export const REDEEM_RETURNED = 'REDEEM_RETURNED'


// #Vaults
export const DEPOSIT_VAULT = 'DEPOSIT_VAULT'
export const DEPOSIT_VAULT_RETURNED = 'DEPOSIT_VAULT_RETURNED'

export const DEPOSIT_ALL_VAULT = 'DEPOSIT_ALL_VAULT'
export const DEPOSIT_ALL_VAULT_RETURNED = 'DEPOSIT_ALL_VAULT_RETURNED'

export const WITHDRAW_VAULT = 'WITHDRAW_VAULT'
export const WITHDRAW_VAULT_RETURNED = 'WITHDRAW_VAULT_RETURNED'

export const WITHDRAW_ALL_VAULT = 'WITHDRAW_ALL_VAULT'
export const WITHDRAW_ALL_VAULT_RETURNED = 'WITHDRAW_ALL_VAULT_RETURNED'


// #Zap
export const ZAP = 'ZAP'
export const ZAP_RETURNED = 'ZAP_RETURNED'

export const SWAP = 'SWAP'
export const SWAP_RETURNED = 'SWAP_RETURNED'

export const TRADE = 'TRADE'
export const TRADE_RETURNED = 'TRADE_RETURNED'

export const GET_CURV_BALANCE = 'GET_CURV_BALANCE'
export const GET_CURV_BALANCE_RETURNED = 'GET_CURV_BALANCE_RETURNED'

export const GET_BEST_PRICE = 'GET_BEST_PRICE'
export const GET_BEST_PRICE_RETURNED = 'GET_BEST_PRICE_RETURNED'


// #APR
export const GET_AGGREGATED_YIELD = 'GET_AGGREGATED_YIELD'
export const GET_AGGREGATED_YIELD_RETURNED = 'GET_AGGREGATED_YIELD_RETURNED'


// #Manage
export const DONATE = 'DONATE'
export const DONATE_RETURNED = 'DONATE_RETURNED'

export const REBALANCE = 'REBALANCE'
export const REBALANCE_RETURNED = 'REBALANCE_RETURNED'

export const GET_CONTRACT_EVENTS = 'GET_CONTRACT_EVENTS'
export const GET_CONTRACT_EVENTS_RETURNED = 'GET_CONTRACT_EVENTS_RETURNED'

export const IDAI = 'IDAI'
export const IDAI_RETURNED = 'IDAI_RETURNED'
